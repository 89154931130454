.preview {
  height: 100%;
  position: relative;

  & > .question {
    min-height: 100% !important;
    position: relative;
  }

  .result {
    position: relative;
  }
}