.login {
  display: inline-block;
  height: 56px;
  padding: 0 15px;
  min-width: 50px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  text-decoration: none;
  color: #fff;
  will-change: background-color;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.login:hover {
  background: rgba(0, 0, 0, 0.2);
}
.profileContainer {
  cursor: pointer;
  height: 56px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profileImg {
  width: 40px;
  border-radius: 50%;
}

.profileModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.profilePopup {
  position: absolute;
  top: 56px;
  right: 10px;
  background: white;
  min-width: 188px;
  height: 280px;
  color: #171717;
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 14px;
}
.profilePopup div {
  margin: 5px;
}
.profilePopup .logout {
  padding: 10px 20px;
  margin-top: 24px;
  border-radius: 6px;
  background-color: #292d44;
  color: #b2b2b2;
  cursor: pointer;
  user-select: none;
  font-weight: normal;
  transition: background-color 0.2s ease;
}
.profilePopup .logout:hover {
  background-color: #373d61;
}
.closeOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
}
