$mainColor: #182446;
$blue: #0057e7;

$maxContainerWidth: 1600px;

$bp-md: 767px;
$bp-lg: 1100px;
$bp-xl: 1600px;

:export {
  mainColor: $mainColor;
}
