.presentation-view {
  background: black;
  .ui-container {
    position: absolute;
    bottom: 0;
    background: #323232;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    transform: translateZ(0px);
    z-index: 4;
    .btn {
      padding: 10px 10px;
      cursor: pointer;
      background: #323232;
      text-align: center;
      color: white;
      &.reset,
      &.exit {
        // border-left: 1px solid white;
      }
      a {
        color: white;
        text-decoration: none;
      }
    }

    .select {
      color: white;
      .MuiSelect-icon,
      .MuiSelect-select {
        color: white;
      }
      .MuiSelect-select {
        padding: 0px 10px;
        margin-right: -5px;
      }
    }

    .slide-label {
      text-align: center;
      padding: 10px;
      color: white;
      border-left: 1px solid white;
      border-right: 1px solid white;
    }
  }

  &__loader-container {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.9);

    div {
      position: relative;
      margin: 0 auto;
      width: 80px;
      top: 250px;
    }
  }
}
