.main-container {
  background: black;
  width: 100%;
  height: 100vh;

  &.is-behind {
    background: transparent !important;
    .main-container {
      background: transparent !important;
    }
    .slides-container {
      z-index: 2;
      pointer-events: none;
      svg > g > g > path {
        &:first-child {
          fill: transparent;
        }
      }
    }
  }
}

.slides-container {
  width: 100%;
  height: 56.25vw;
  max-height: 100vh;
  max-width: 177.78vh;
  // margin: auto;
  // position: absolute;
  // top: 0;
  // left: 0;
  // bottom: 0;
  // right: 0;

  &.portrait {
    height: 100vh;
    width: 56.25vh;
    max-width: 100vw;
    max-height: auto;
  }

  > svg {
    flex: auto
  }
}



.video-bg {
  border: none;
  width: 100%;
  height: 100%;
  position: absolute;
  background: black;
}
.hidden {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

video {
  transform: translate3d(0, 0, 0);
  object-fit: cover;
  background: black;
}

path[fill="#00ffff"]{
  fill: transparent !important;
}
