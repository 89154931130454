@import '../styles/index.scss';

.loader {
  min-width: 80px;
  path {
    animation: rotate 0.8s linear infinite;
    transform: scale(2);
    transform-origin: center center;
    fill: $blue;
  }
}

@keyframes rotate {
  100% {
    transform: scale(2) rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
