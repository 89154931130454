.App {
  height: 100vh;
  overflow: auto;
}

.fade-appear {
  opacity: 0.01;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 300ms ease;
}

.fade-enter {
  opacity: 0.01;
  .scale-in {
    transform: scale(0.85);
  }
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms ease;
  position: absolute;
  top: 0;
  .scale-in {
    transition: transform 350ms ease;
    transform: scale(1);
  }
}

.fade-leave {
  opacity: 1;
  .scale-in {
    transition: transform 350ms ease;
    transform: scale(1);
  }
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 400ms ease;
  transition-delay: 200ms;
  .scale-in {
    transition: transform 350ms ease;
    transform: scale(0.9);
  }
}

// Material UI
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 18px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.Mui-disabled {
  color: grey !important;
}
