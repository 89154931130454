@font-face {
  font-family: 'Matter';
  src: url('../assets/fonts/Matter-Regular.woff2') format('woff2'),
    url('../assets/fonts/Matter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
body,
div,
h1,
h2,
h3,
h4,
p {
  font-family: Matter, 'sans-serif' !important;
}
* {
  box-sizing: border-box;
}
body {
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background: transparent;
}
