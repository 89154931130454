.chart-items {
  margin: 0 auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.empty {
  height: calc(100% + 110px);
}
.data-group {
  display: inline-block;
  transition: opacity 0.4s ease;
}
.bar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: width 0.3s ease;
}
.bar-container {
    display: flex;
}
.no-anim {
  .bar {
    transition: width 0s ease;
  }
}

.count {
  font-size: 14px;
  width: 50px;
}

.label {
  color: black;
  font-size: 16px;
  line-height: 24px;
  margin: 32px 0 8px 0;
  max-width: 320px;
}

.negative {
  color: #e51010;
}

.container {
  height: 55vh;
  margin-top: 32px;
  position: relative;
  opacity: 0;
  transition: opacity 0.3s ease;
  width: 100%;
  &.show {
    opacity: 1;
  }
}

.axis {
  border-bottom: 2px solid #146f5a;
  border-left: 2px solid #146f5a;
  height: 100%;
  left: 3%;
  position: absolute;
  top: 0;
  width: 95%;
  z-index: 2;
}

.title {
  font-size: 24px;
  text-align: center;
  width: 90%;
  margin: 0 auto;
}

.title-container {
  display: block;
  margin-top: 30px;
  width: 100%;
}

@media only screen and (min-width: 767px) {
  .title {
    font-size: 45px;
    text-align: center;
  }
  .bar-container {
    margin: 18px 0px;
  }
  .chart-items {
    margin: 10px 4%;
    width: 90%;
  }
  .container {
    margin-top: 120px;
  }
  .empty {
    height: calc(100% + 120px);
  }
  .count {
    font-size: 30px;
    width: 105px;
  }

  .label {
    font-size: 20px;
    margin-top: 20px;
    max-width: 80%;
  }
}
.highlighted {
  .data-group {
    opacity: 0.5;
    &.c {
      opacity: 1;
      color: #00D2D2 !important; //TODO remove holiday only
      .count {
        color: #00D2D2 !important;
      }
    }
  }
  .bar {
    opacity: 0.5;
    &.c {
      opacity: 1;
      background: #00D2D2 !important;  //TODO remove holiday only
    }
  }
 
}

@media only screen and (max-height: 400px) {
  .title {
    display: none;
  }
  .container {
    margin-top: 20px;
  }
  .chart-items {
    margin: 9% 0% 0% 0%;
  }
  .title-container {
    margin-top: 20px;
  }
  .count {
    font-size: 10px;
  }
  .label {
    font-size: 10px;
    height: 0px;
  }
  .empty {
    height: calc(100% + 10px);
  }
}

@media only screen and (max-height: 200px) {
  .label {
    font-size: 8px;
    height: 0px;
    margin-top: 5%;
  }
  .count {
    font-size: 8px;
  }
  .empty {
    height: calc(100% + 3px);
  }
}
